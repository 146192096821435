import { isString, unescape } from 'lodash'
import router from '../../router'
import StorageService from '../../services/storage'
import { UserService } from '../../services/user'
import pendo from '../../util/integrations/pendo'
import logRocket from '../../util/integrations/logRocket'
import {
  clearSessionProfileCookie,
  getProfileAvatarUrl,
  makeSessionProfileCookie,
  visitCountCookie
} from '../../util/profile'
import { stateMerge } from '../../util/stateMerge'
import i18next from '../../i18next'
import waitForValue from '../../util/waitForValue'
import isDemoUser from '../../util/isDemoUser'
import { formatTranslationLanguage } from '../../util/formatTranslationLanguage'

export const registrationFields = {
  firstName: null,
  lastName: null,
  email: null,
  password: null,
  password2: null,
  language: null,
  country: null,
  state: null,
  location: null,
  locationID: 0,
  department: null,
  departmentID: 0,
  privacyAccepted: null,
  avatarFileName: '',
  // hCaptchaToken: '',
  accessCode: ''
}

export const profileFields = {
  invalidate: true,
  accountID: null,
  firstName: null,
  lastName: null,
  email: null,
  title: null,
  location: null,
  language: {
    id: null,
    name: null,
    isoCode: null
  },
  department: null,
  dataConsentDT: null,
  companyProfileSearch: null,
  publicProfileSearch: null,
  pathways: [],
  privacyAccepted: null,
  avatarFileName: null,
  avatarUrl: null,
  assessments: [],
  hasCatalystData: true, // default to true so we don't trap them in that page if there's an error
  style: {
    style: null,
    angle: null,
    vector: null,
    extras: [
      {
        // String id of a product
        category: null,
        // String i || i_is || empty
        style: null
      }
    ]
  },
  continua: [
    {
      // String for scale name label LeftLabel_RightLabel
      scaleName: null,
      // Number from -1 to 1
      rawValue: null,
      // Plottable score 0 to 100
      value: null
    }
  ],
  organizationID: null,
  locationID: null,
  departmentID: null,
  countryID: null,
  stateID: null,
  admin: {
    accountID: null,
    distributorID: null,
    logo: null,
    companyName: null,
    companyInfo1: null,
    companyInfo2: null,
    companyInfo3: null,
    companyInfo4: null,
    companyInfo5: null,
    companyInfo6: null,
    companyInfo7: null,
    url: null,
    assigneeEmail: null,
    skipDemographics: false
  },
  partner: {
    accountID: null,
    companyName: null,
    contactLine1: null,
    contactLine2: null,
    contactLine3: null,
    contactLine4: null,
    url: null
  },
  userRoles: [],
  catalystLastLoginDT: null,
  catalystLastOrganizationID: null,
  previousCatalystLoginDate: null,
  previousCatalystOrganizationID: null
}

export const profileEditFields = {
  firstName: null,
  lastName: null,
  email: null,
  avatarFileName: null,
  avatarUrl: null,
  language: null,
  department: null,
  departmentID: 0,
  countryID: null,
  stateID: null,
  location: null,
  locationID: 0,
  companyProfileSearch: null
}

export const emailCommunicationPreferencesEditsFlags = {
  featureAnnouncementEmails: true
}

export const catalystFeaturesFields = {
  accountDashboard: false
}

const state = {
  authenticating: false,
  authenticationErrorCode: 0,
  authenticationError: '',
  avatarPending: false,
  avatarErrorCode: 0,
  avatarSuccessCode: 0,
  avatarError: '',
  verification: {
    pending: false,
    status: 0,
    error: '',
    email: null
  },
  resetGrant: {
    pending: false,
    status: 0,
    error: '',
    email: null
  },
  passwordReset: {
    pending: false,
    validating: true,
    validateCodeStatus: 0,
    saveStatus: 0,
    error: '',
    functionCode: ''
  },
  user: {
    ...StorageService.getUser(),
    email: null
  },
  registration: {
    ...registrationFields
  },
  profile: {
    pending: false,
    status: 0,
    error: '',
    ...profileFields
  },
  emailCommunicationPreferences: {
    flags: {
      ...emailCommunicationPreferencesEditsFlags
    },
    pending: false,
    status: 0,
    error: ''
  },
  emailCommunicationPreferencesEdits: {
    ...emailCommunicationPreferencesEditsFlags
  },
  accessCodeAccount: {
    pending: false,
    learnerID: 0,
    error: '',
    status: 0,
    organizationID: 0
  },
  profileEdits: {
    ...profileEditFields
  },
  login: {
    username: null,
    isNew: true,
    federationName: null
  },
  catalystFeatures: {
    pending: false,
    error: '',
    status: 0,
    features: { ...catalystFeaturesFields }
  }
}

const getters = {
  loggedIn: state => !!(state.user.tokens && state.user.tokens.access_token),
  authenticationErrorCode: state => state.authenticationErrorCode,
  authenticationError: state => state.authenticationError,
  authenticating: state => state.authenticating,
  currentUserFirstName: state => state.profile.accountID && state.profile.firstName ? state.profile.firstName : null,
  currentUserLastName: state => state.profile.accountID && state.profile.lastName ? state.profile.lastName : null,
  currentUserAvatar: state => state.profile && getProfileAvatarUrl(state.profile),
  currentUserProfile: state => state.profile.accountID ? state.profile : null,
  currentUserStyle: state => state.profile.style,
  currentUserLanguage: state => state.profile.language.isoCode,
  currentAssigneeEmail: state => state.profile.admin && state.profile.admin.assigneeEmail ? state.profile.admin.assigneeEmail : null,
  settingsPending: state => state.profile.pending || state.passwordReset.pending || state.avatarPending || state.emailCommunicationPreferences.pending,
  passwordStatus: state => state.passwordReset.saveStatus,
  profileStatus: state => state.profile.status,
  avatarStatus: state => (state.avatarErrorCode > 0) ? state.avatarErrorCode : state.avatarSuccessCode,
  currentRegistrationFields: state => state.registration,
  currentProfileEdits: state => state.profileEdits,
  emailCommunicationPreferencesEdits: state => state.emailCommunicationPreferencesEdits,
  // for now, only returning state of most recently assigned assessment
  accessCode: state => state.profile.assessments.length ? state.profile.assessments[0].accessCode : null,
  assessment: state => state.profile.assessments.length ? state.profile.assessments[0] : null,
  accessCodes: state => state.profile.assessments.map(assessment => assessment.accessCode),
  accountID: state => state.profile.accountID,
  assessmentsStarted: state => state.profile.assessments.length ? !!state.profile.assessments[0].questionsAnswered : false,
  assessmentsComplete: state => state.profile.assessments.length ? state.profile.assessments[0].isComplete : false,
  pathways: ({ profile: { pathways } }) => pathways
    ? pathways.filter(({ order }) => order > 0).map(({ id, order, textCode, route, pages }) => ({
      id,
      order,
      label: textCode,
      value: `/${route}`,
      pages
    }))
    : [],
  isMissingProfileFields: state => !state.profile.hasCatalystData || !state.profile.firstName || !state.profile.lastName,
  accessCodeOrganization: state => state.accessCodeAccount.organizationID,
  emailVerified: state => state.verification.email && state.verification.status >= 200 && state.verification.status < 300,
  reportOptions: state => state.profile.assessments.length ? state.profile.assessments[0].reportOptions : null,
  isFacilitatorAccount: ({ profile: { userRoles } }) => userRoles && userRoles.some(role => role.roleType.roleTypeID === 2),
  isOrganizationAdminAccount: ({ profile: { pathways } }) => pathways && !!pathways.find(pathway => pathway.route === 'admin/authenticate'),
  companyProfileSearch: ({ profile }) => profile
    ? profile.companyProfileSearch
    : false,
  loginUsername: state => state.login.username,
  isUserSso: state => state.user.isSso || !!state.login.federationName || false,
  showSsoAlert: state => state.user.showSsoAlert || false,
  loginCount: state => state.profile.loginCount,
  organizationChanged: state => state.profile.previousCatalystOrganizationID !== state.profile.organizationID,
  hasDashboardAccess: state => state.catalystFeatures.features.accountDashboard
}

const actions = {
  resetRegistration ({ commit, state }) {
    commit('registrationChange', { ...registrationFields, accessCode: state.registration.accessCode })
  },
  updateRegistration ({ commit }, input) {
    commit('registrationChange', input)
  },

  async grantReset ({ commit, state }) {
    commit('grantResetRequest')
    try {
      await UserService.requestPasswordReset(state.resetGrant.email)
      commit('grantResetSuccess')
    } catch (e) {
      commit('grantResetError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
    }
  },

  async verifyEmail ({ commit }, { email, accessCode }) {
    commit('verifyEmailRequest', email)
    try {
      const verification = await UserService.verifyEmail({ email, accessCode })
      if (verification) {
        commit('verifyEmailSuccess')
      }
    } catch (e) {
      commit('verifyEmailError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async authenticate ({ commit }, { input, register = false }) {
    commit('authRequest')
    try {
      const user = register ? await UserService.register(input) : await UserService.login(input)
      commit('authSuccess', {
        ...user,
        email: input.email || input.Username || input.Email
      })

      // Redirect the user to the page they first tried to visit or home
      router.push(router.history.current.query.redirect || '/')
      return true
    } catch (e) {
      commit('authError', { errorCode: e.errorCode, errorMessage: e.message })
      return false
    }
  },

  async authenticateSso ({ commit }, { code, federation, apiState }) {
    commit('authRequest')
    try {
      const user = await UserService.loginSso(code, federation, apiState)

      // Don't want to save state info as part of the user object,
      // only needed by route guard
      commit('authSuccess', { ...user, state: null })

      return user
    } catch (e) {
      commit('authError', { errorCode: e.errorCode, errorMessage: e.message })
      return null
    }
  },

  async adminAuth ({ commit }, token) {
    try {
      const user = await UserService.adminLogin(token)
      commit('authSuccess', {
        ...user
      })

      router.push('/')
      return true
    } catch (e) {
      commit('authError', { errorCode: e.errorCode, errorMessage: e.message })
      return false
    }
  },

  async register ({ commit, dispatch, state, getters }, avatarData) {
    commit('authRequest')

    if (!!avatarData && !!avatarData.ImageData) {
      try {
        const { fileName, avatarUrl } = await UserService.avatar(avatarData)
        commit('registrationChange', {
          avatarFileName: fileName,
          avatarUrl: avatarUrl
        })
      } catch (e) {
        commit('avatarError', {
          errorCode: e.errorCode,
          errorMessage: e.message
        })
        return false
      }
    }

    if (getters.isUserSso) {
      await UserService.register({ ...state.registration, password: 'SSOuser123' }, true)
      commit('authSuccess', {})
      return true
    }

    return await dispatch('authenticate', {
      input: state.registration,
      register: true
    })
  },

  async verifyResetCode ({ commit }, functionCode) {
    commit('verifyResetCodeRequest', functionCode)
    if (!functionCode) {
      commit('verifyResetCodeError', {
        errorCode: 404,
        errorMessage: 'code is undefined'
      })
      return
    }

    try {
      await UserService.verifyResetCode(functionCode)
      commit('verifyResetCodeSuccess')
    } catch (e) {
      commit('verifyResetCodeError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
    }
  },

  async resetPassword ({ commit, state }, password) {
    commit('resetPasswordRequest')

    try {
      await UserService.resetPassword(state.passwordReset.functionCode, password)
      commit('resetPasswordSuccess')
    } catch (e) {
      commit('resetPasswordError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
    }
  },

  async changePassword ({ commit, state }, { currentPassword, newPassword }) {
    commit('resetPasswordRequest')

    try {
      await UserService.changePassword(
        state.profile.email,
        currentPassword,
        newPassword
      )
      commit('resetPasswordSuccess')
    } catch (e) {
      commit('resetPasswordError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
    }
  },

  async logout ({ commit, dispatch }) {
    i18next.changeLanguage('en-US')
    clearSessionProfileCookie()
    UserService.logout()
    pendo.resetIdentity()
    logRocket.resetIdentity()
    StorageService.clearDemoUser()

    commit('resetStore', null, { root: true })
    dispatch('featureFlags/updateContext', {
      anonymous: true
    }, { root: true })
    commit('authCleared')
    commit('verifyEmailCleared')
    commit('profileCleared')

    if (isDemoUser()) window.location.href = '/v2/demo'
    return true
  },

  async populateSupportChatFields ({ state }) {
    try {
      if (window.embedded_svc) {
        window.embedded_svc.settings.prepopulatedPrechatFields.FirstName = unescape(state.profile.firstName)
        window.embedded_svc.settings.prepopulatedPrechatFields.LastName = unescape(state.profile.lastName)
        window.embedded_svc.settings.prepopulatedPrechatFields.Email = state.profile.email
        return true
      }

      return false
    } catch (e) {
      // We don't want to store this as an error in the store
      // as that will trigger a 500 page, but we still want a log
      // if anything goes wrong here.
      // eslint-disable-next-line no-console
      console.error(e)
      return false
    }
  },

  async fetchProfile ({ commit, state, getters, rootGetters, dispatch }) {
    if (!state.profile.invalidate) return true

    commit('profileRequest')
    try {
      const profile = await UserService.profile()

      commit('profileRequestSuccess', profile)

      pendo.identify()
      pendo.updateProfile()

      logRocket.identify()

      waitForValue(
        () => rootGetters['featureFlags/current'].LANGUAGE_SELECTOR,
        true,
        false,
        10000
      ).then(() => {
        if (rootGetters['featureFlags/current'].LANGUAGE_SELECTOR) {
          i18next.changeLanguage(getters.currentUserLanguage)
        }
      })

      dispatch('featureFlags/updateContext', {
        kind: 'user',
        key: profile.accountID,
        assessmentsStarted: getters.assessmentsStarted,
        assessmentsComplete: getters.assessmentsComplete,
        style: getters.currentUserStyle,
        organizationChanged: getters.organizationChanged,
        organizationID: profile.organizationID,
        isDemoUser: isDemoUser(),
        isOrganizationAdminAccount: getters.isOrganizationAdminAccount,
        isUserSso: getters.isUserSso,
        companyProfileSearch: getters.companyProfileSearch
      }, { root: true })

      waitForValue(
        () => rootGetters['featureFlags/current'].LANGUAGE_SELECTOR,
        true,
        false,
        2000
      ).then((LANGUAGE_SELECTOR) => {
        if (LANGUAGE_SELECTOR) i18next.changeLanguage(formatTranslationLanguage(getters.currentUserLanguage))
      })

      if (getters.assessment) {
        makeSessionProfileCookie(getters.assessment.isComplete, getters.assessment.fromProduct)
      }

      if (getters.loginCount) {
        visitCountCookie(getters.loginCount)
      }

      if (profile.previousCatalystOrganizationID === profile.organizationID) {
        StorageService.deleteFeatureAnnouncement('organization_changed')
      }

      if (isDemoUser()) {
        const profile = StorageService.getDemoUser()
        commit('profileRequestSuccess', profile)
      }

      dispatch('getCatalystFeatures')

      dispatch('populateSupportChatFields')

      return true
    } catch (e) {
      commit('profileRequestError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async updateProfile ({ commit, dispatch, state, rootGetters }) {
    commit('profileRequest')

    try {
      if (state.profileEdits.avatarUrl == null) {
        commit('avatarRemove')
      } else if (state.profileEdits.avatarUrl.startsWith('data:image')) {
        const uploadedAvatarData = await dispatch('uploadAvatar', {
          ImageData: state.profileEdits.avatarUrl,
          ImageType: 'png'
        })

        // If new avatar was uploaded, use it, otherwise let's
        // just use the old existing.
        if (uploadedAvatarData) {
          commit('profileEditUpdate', uploadedAvatarData)
        } else {
          commit('profileEditUpdate', {
            avatarFileName: state.profile.avatarFileName,
            avatarUrl: state.profile.avatarUrl
          })
        }
      }

      if (state.profileEdits.language?.isoCode) {
        commit('profileEditUpdate', { language: state.profileEdits.language.isoCode })
      }

      const updateProfileResponse = await UserService.updateProfile(state.profileEdits)

      if (rootGetters['featureFlags/current'].LANGUAGE_SELECTOR) {
        i18next.changeLanguage(updateProfileResponse.data.language.isoCode)
      }

      commit('profileUpdateSuccess', updateProfileResponse.data)
      return true
    } catch (e) {
      commit('profileRequestError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async uploadAvatar ({ commit }, avatarData) {
    commit('avatarRequest')

    try {
      const { fileName, url } = await UserService.avatar(avatarData)
      commit('avatarUploadSuccess')

      return { avatarFileName: fileName, avatarUrl: url }
    } catch (e) {
      commit('avatarError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })

      return false
    }
  },

  async updateShare ({ commit, state, dispatch }, value) {
    commit('profileEditUpdate', { ...state.profile, companyProfileSearch: value })
    return await dispatch('updateProfile')
  },

  async completeAssessment ({ commit, rootGetters }) {
    commit('invalidateProfile')

    // clear pendo profile so it'll reload completed assessment info
    StorageService.setPendoInfo({ profileAdded: false })
    return true
  },

  async accessCode ({ dispatch, getters }, accessCode) {
    if (getters.loggedIn) {
      return dispatch('addAccessCode', accessCode)
    } else {
      return dispatch('accessCodeUser', accessCode)
    }
  },

  async accessCodeUser ({ commit }, accessCode) {
    commit('accessCodeUserRequest')
    try {
      const accessCodeData = await UserService.fetchUserByAccessCode(accessCode)
      commit('accessCodeUserSuccess', { ...accessCodeData, accessCode })
      return true
    } catch (e) {
      commit('accessCodeUserError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async addAccessCode ({ commit }, accessCode) {
    commit('accessCodeRequest')
    try {
      await UserService.accessCode(accessCode)
      commit('accessCodeSuccess')
      return true
    } catch (e) {
      if (e.errorCode === 409) {
        // 409 (Conflict) is returned if the AC has already been added
        // We don't need to error if that's the case
        commit('accessCodeAlreadyAdded')
        return true
      }

      commit('accessCodeError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async getSsoFederation ({ commit }, username) {
    commit('federationRequest', username)
    try {
      const { federationName, status } = await UserService.federation(username)
      const result = federationName
        ? { federationName, isNew: status === 0 }
        : {}
      commit('federationSuccess', result)
      return result
    } catch (e) {
      commit('federationError', { errorCode: e.errorCode, errorMessage: e.message })
      return null
    }
  },

  async updateEmailCommunicationPreferences ({ commit, state }) {
    commit('emailCommunicationPreferencesRequest')

    try {
      const updatedFlags = await UserService.updateEmailCommunicationPreferences(
        state.emailCommunicationPreferencesEdits
      )
      commit('emailCommunicationPreferencesSuccess', updatedFlags)
      return updatedFlags
    } catch (e) {
      commit('emailCommunicationPreferencesError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async getEmailCommunicationPreferences ({ commit }) {
    commit('emailCommunicationPreferencesRequest')

    try {
      const flags = await UserService.getEmailCommunicationPreferences()
      commit('emailCommunicationPreferencesSuccess', flags)
      return flags
    } catch (e) {
      commit('emailCommunicationPreferencesError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return false
    }
  },

  async getCatalystFeatures ({ commit }) {
    commit('catalystFeaturesRequest')
    try {
      const features = await UserService.getCatalystFeatures()
      commit('catalystFeaturesSuccess', features)
      return features
    } catch (e) {
      commit('catalystFeaturesError', {
        errorCode: e.errorCode,
        errorMessage: e.message
      })
      return null
    }
  }
}

const mutations = {
  invalidateProfile (state) {
    state.profile.invalidate = true
  },
  verifyEmailRequest (state, email) {
    state.verification.pending = true
    state.verification.status = 0
    state.verification.error = ''
    state.verification.email = email
  },
  verifyEmailSuccess (state) {
    state.verification.pending = false
    state.verification.status = 204
    state.verification.error = ''
  },
  verifyEmailError (state, { errorCode, errorMessage }) {
    state.verification.pending = false
    state.verification.status = errorCode
    state.verification.error = errorMessage
  },
  verifyEmailCleared (state) {
    state.verification.pending = false
    state.verification.status = 0
    state.verification.error = ''
    state.verification.email = null
  },
  avatarRequest (state) {
    state.avatarPending = true
    state.avatarErrorCode = 0
    state.avatarSuccessCode = 0
  },
  profileRequest (state) {
    state.profile.pending = true
    state.profile.status = 0
    state.profile.error = ''
  },
  profileRequestSuccess (state, profile) {
    state.profile = Object.assign({}, state.profile, profile)
    state.profile.assessments.sort((a, b) => a.assignedDT < b.assignedDT ? 1 : -1)
    state.profile.invalidate = false
    state.profile.pending = false
    state.profile.status = 200
    state.profile.error = ''
  },
  profileRequestCancel (state) {
    state.profile.pending = false
    state.profile.status = 0
    state.profile.error = ''
  },
  profileRequestError (state, { errorMessage, errorCode }) {
    state.profile.pending = false
    state.profile.error = errorMessage
    state.profile.status = errorCode
  },
  emailCommunicationPreferencesInput (state, input) {
    for (const field in input) {
      if (Object.prototype.hasOwnProperty.call(state.emailCommunicationPreferencesEdits, field)) {
        state.emailCommunicationPreferencesEdits[field] = input[field]
      }
    }
  },
  emailCommunicationPreferencesRequest (state) {
    state.emailCommunicationPreferences.pending = true
    state.emailCommunicationPreferences.status = 0
    state.emailCommunicationPreferences.error = ''
  },
  emailCommunicationPreferencesError (state, { errorMessage, errorCode }) {
    state.emailCommunicationPreferences.pending = false
    state.emailCommunicationPreferences.error = errorMessage
    state.emailCommunicationPreferences.status = errorCode
  },
  emailCommunicationPreferencesSuccess (state, flags) {
    state.emailCommunicationPreferences.flags = Object.assign({}, state.emailCommunicationPreferences.flags, flags)
    state.emailCommunicationPreferencesEdits = Object.assign({}, state.emailCommunicationPreferencesEdits, flags)
    state.emailCommunicationPreferences.pending = false
    state.emailCommunicationPreferences.status = 200
    state.emailCommunicationPreferences.error = ''
  },
  avatarUploadSuccess (state) {
    stateMerge(state, { profile: state.profileEdits })
    state.avatarPending = false
    state.avatarSuccessCode = 200
  },
  avatarRemove (state) {
    state.profile.avatarFileName = null
    state.profile.avatarUrl = null
  },
  profileUpdateSuccess (state, profile) {
    const filteredObject = Object.fromEntries(
      Object.entries(profile).filter(([_, value]) => value !== null)
    )
    stateMerge(state, { profile: filteredObject })
    for (const field in filteredObject) {
      if (Object.prototype.hasOwnProperty.call(state.profileEdits, field)) {
        const value = filteredObject[field]
        state.profileEdits[field] = isString(value) ? unescape(value) : value
      }
    }
    state.profile.pending = false
    state.profile.status = 200
    state.profile.error = ''
    state.profile.hasCatalystData = true
  },
  profileEditUpdate (state, input) {
    for (const field in input) {
      if (Object.prototype.hasOwnProperty.call(state.profileEdits, field)) {
        state.profileEdits[field] = input[field]
      }
    }
  },
  updateEmailCommunicationPreferences (state, input) {
    for (const field in input) {
      if (Object.prototype.hasOwnProperty.call(state.emailCommunicationPreferencesEdits, field)) {
        state.emailCommunicationPreferencesEdits[field] = input[field]
      }
    }
  },
  resetGrantEmail (state, email) {
    state.resetGrant.email = email
    state.resetGrant.error = ''
    state.resetGrant.status = 0
  },
  grantResetRequest (state) {
    state.resetGrant.pending = true
    state.resetGrant.error = ''
    state.resetGrant.status = 0
  },
  grantResetSuccess (state) {
    state.resetGrant.pending = false
    state.resetGrant.error = ''
    state.resetGrant.status = 200
    state.resetGrant.email = null
  },
  grantResetError (state, { errorCode, errorMessage }) {
    state.resetGrant.pending = false
    state.resetGrant.error = errorMessage
    state.resetGrant.status = errorCode
  },
  registrationChange (state, input) {
    for (const field in input) {
      state.registration[field] = input[field]
    }
    state.authenticationError = ''
    state.authenticationErrorCode = 0
    state.avatarError = ''
    state.avatarErrorCode = 0
  },
  hCaptchaChange (state, token) {
    state.registration.hCaptchaToken = token
    state.authenticationError = ''
    state.authenticationErrorCode = 0
  },

  authInput (state) {
    state.authenticating = false
    state.authenticationError = ''
    state.authenticationErrorCode = 0
    state.avatarError = ''
    state.avatarErrorCode = 0
  },

  authRequest (state) {
    state.authenticating = true
    state.authenticationError = ''
    state.authenticationErrorCode = 0
    state.avatarError = ''
    state.avatarErrorCode = 0
  },

  authSuccess (state, user) {
    state.user = { ...user }
    state.authenticating = false
  },

  authError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationError = errorMessage
    state.authenticationErrorCode = errorCode
    // state.registration.hCaptchaToken = null
  },

  avatarError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.avatarPending = false
    state.avatarError = errorMessage
    state.avatarErrorCode = errorCode
    // state.registration.hCaptchaToken = null
  },

  authCleared (state) {
    state.user = {}
  },

  profileCleared (state) {
    state.profile = Object.assign({}, profileFields)
  },

  // ResetPassword
  verifyResetCodeRequest (state, code) {
    state.passwordReset.error = ''
    state.passwordReset.validateCodeStatus = 0
    state.passwordReset.functionCode = code
  },
  verifyResetCodeSuccess (state) {
    state.passwordReset.validating = false
    state.passwordReset.error = ''
    state.passwordReset.validateCodeStatus = 200
  },
  verifyResetCodeError (state, { errorCode, errorMessage }) {
    state.passwordReset.validating = false
    state.passwordReset.error = errorMessage
    state.passwordReset.validateCodeStatus = errorCode
  },
  resetPasswordRequest (state) {
    state.passwordReset.pending = true
    state.passwordReset.error = ''
    state.passwordReset.saveStatus = 0
  },
  resetPasswordSuccess (state) {
    state.passwordReset.pending = false
    state.passwordReset.error = ''
    state.passwordReset.saveStatus = 200
  },
  resetPasswordError (state, { errorCode, errorMessage }) {
    state.passwordReset.pending = false
    state.passwordReset.error = errorMessage
    state.passwordReset.saveStatus = errorCode
  },
  resetPasswordInput (state) {
    state.passwordReset.error = ''
    state.passwordReset.saveStatus = 0
  },

  // AccessCode
  accessCodeUserRequest (state) {
    state.accessCodeAccount.pending = true
    state.accessCodeAccount.error = ''
    state.accessCodeAccount.status = 0
    state.accessCodeAccount.learnerID = 0
    state.accessCodeAccount.organizationID = 0
    state.registration.accessCode = ''
  },
  accessCodeUserSuccess (state, { learnerID, organizationID, accessCode }) {
    state.accessCodeAccount.pending = false
    state.accessCodeAccount.error = ''
    state.accessCodeAccount.status = 200
    state.accessCodeAccount.learnerID = learnerID
    state.accessCodeAccount.organizationID = organizationID
    state.registration.accessCode = accessCode
  },
  accessCodeUserError (state, { errorCode, errorMessage }) {
    state.accessCodeAccount.pending = false
    state.accessCodeAccount.error = errorMessage
    state.accessCodeAccount.status = errorCode
    state.accessCodeAccount.learnerID = 0
    state.accessCodeAccount.organizationID = 0
    state.registration.accessCode = ''
  },
  accessCodeRequest (state) {
    state.accessCodeAccount.pending = true
    state.accessCodeAccount.error = ''
    state.accessCodeAccount.status = 0
    state.accessCodeAccount.learnerID = 0
  },
  accessCodeSuccess (state) {
    state.accessCodeAccount.pending = false
    state.accessCodeAccount.error = ''
    state.accessCodeAccount.status = 200
    state.profile.invalidate = true
  },
  accessCodeAlreadyAdded (state) {
    state.accessCodeAccount.pending = false
    state.accessCodeAccount.error = ''
    state.accessCodeAccount.status = 200
  },
  accessCodeError (state, { errorCode, errorMessage }) {
    state.accessCodeAccount.pending = false
    state.accessCodeAccount.error = errorMessage
    state.accessCodeAccount.status = errorCode
  },

  // Assessment
  assessmentStarted (state) {
    state.profile.assessments[0].questionsAnswered = 1
  },

  // Federation
  federationRequest (state, username) {
    state.authenticating = true
    state.login.username = username
  },
  federationSuccess (state, { federationName, isNew }) {
    state.authenticating = false
    state.login.isNew = isNew
    state.login.federationName = federationName
  },
  federationError (state, { errorCode, errorMessage }) {
    state.authenticating = false
    state.authenticationError = errorMessage
    state.authenticationErrorCode = errorCode
  },
  ssoAlertHidden (state) {
    state.user.showSsoAlert = false
  },

  // Features
  catalystFeaturesRequest (state) {
    state.catalystFeatures.pending = true
    state.catalystFeatures.status = 0
    state.catalystFeatures.error = ''
  },
  catalystFeaturesError (state, { errorMessage, errorCode }) {
    state.catalystFeatures.pending = false
    state.catalystFeatures.error = errorMessage
    state.catalystFeatures.status = errorCode
  },
  catalystFeaturesSuccess (state, features) {
    state.catalystFeatures.features = {
      accountDashboard: features['catalyst/account-dashboard']
    }
    state.catalystFeatures.pending = false
    state.catalystFeatures.status = 200
    state.catalystFeatures.error = ''
  }
}

export const account = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
