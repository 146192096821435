import TextService from './services/text'
import store from './store'

export const initOptions = {
  debug: false,
  partialBundledLanguages: true,
  backend: {
    loadPath: '{{lng}},{{ns}}',
    request: async (options, url, payload, callback) => {
      store.commit('app/prefetchStart')

      let returnedTexts = {}

      try {
        const [lng, ns] = url.split(',')

        const data = await TextService.process({
          context: ns,
          language: lng
        })

        returnedTexts = data[0].texts
      } catch (e) {}

      callback(null, {
        data: returnedTexts,
        status: 200
      })

      store.commit('app/prefetchEnd')
    }
  },
  defaultNS: 'common',
  // i18next plural resolver only supports langs with dashes
  // but we use underscores in our APIs, therefore
  // we have to define with dashes here and transform when
  // we do the API call. :{
  lng: 'en-US',
  fallbackLng: false,
  supportedLngs: [
    'en-US',
    'ja-JP',
    'da-DK',
    'eo'
  ],
  ns: ['common'],
  interpolation: {
    escapeValue: false
  },
  resources: {}
}
